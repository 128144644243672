<template>
  <div>
    <v-row>
      <v-col md="4">
        <v-card>
          <v-card-title>{{ $t('stock.title') }}</v-card-title>
          <v-card-text>
            <!-- <v-list v-if="indices.length" subheader two-line>
              <index-list-item :index="index" v-for="index in indices" :key="`index-${index.id}`"/>
            </v-list> -->
            <grid-view
              ref="grid"
              :headers="headers"
              endpoint="msi-index?expand=stockExchange.country"
              model="StockExchange"
              :loading="loading"
            >

              <template v-slot:item.stockExchange.name="{ item }">
                <CountryFlag :code="item.stockExchange.country.code" v-if="item.stockExchange.country" />
                {{ (item.stockExchange.name) }}
                <!-- <sub>{{ item.stockExchange.isin }}</sub> -->
              </template>

              <template v-slot:item.stockExchange.last.now="{ item }">
                {{ (item.stockExchange.last.now) }}
              </template>

              <template v-slot:item._actions="{ item }">
                <v-btn @click="viewIndex(item)" icon small>
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
                <v-btn @click="activateIndex(item)" icon small>
                  <v-icon v-if="item.stockExchange.active" small>fa fa-unlock-alt</v-icon>
                  <v-icon v-else="!item.stockExchange.active" small>fa fa-lock</v-icon>
                </v-btn>
                <v-btn @click="viewSetting(item,item.stockExchange.id,item.stockExchange.name)" icon small>
                  <v-icon small>fa fa-cogs</v-icon>
                </v-btn>
              </template>
            </grid-view>
          </v-card-text>
          <v-card-actions>
            <v-btn right @click="addDialog = true">Ajouter</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="8">
        <v-card>
          <v-card-title>World Map</v-card-title>
          <v-card-text>
            <world v-if="!loading" @seiClick="viewIndex" :indices="indices"/>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <!-- edit dialog -->
    <v-dialog v-model="addDialog" max-width="600">
      <msi-index-form @done="onIndexAdded" />
    </v-dialog>

  </div>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import World from "@/components/charts/World";
import CountryFlag from "@/components/CountryFlag";
import MsiIndexForm from "@/components/stock/MsiIndexForm";
import IndexListItem from "@/components/stock/IndexListItem";
import GridView from "@/components/GridView";

@Component({components: {IndexListItem,GridView,CountryFlag, MsiIndexForm, World}, mixins: [formatter]})
export default class IndexIndex extends Vue {

  indices = [];
  addDialog = false;
  loading = true;

  created() {
    this.getIndices();
  }

  get headers() {
    return [
      {text: '', value: '_actions',},
      {text: this.$i18n.t('name'), value: 'stockExchange.name',},
      {text: this.$i18n.t('last'), value: 'stockExchange.last.now',},
    ];
  }
  onIndexAdded() {
    this.addDialog = false;
    this.getIndices();
  }

  getIndices() {
    this.$api.get(`msi-index?expand=stockExchange.country`).then(res => {
      this.indices = res.data;
      this.loading = false;
    });
  }

  viewSetting(index,stock,name) {
    // Encode the name parameter
    const encodedName = encodeURIComponent(name);
    this.$router.push(`/stock/${index.id}/setting/${stock}/${encodedName}`);
  }

  viewIndex(index) {
    this.$router.push(`/stock/${index.id}`);
  }

  activateIndex(index) {
    var active = 'désactiver';var activeVal = 1;
    if(index.stockExchange.active == 1){
      activeVal = 0;
      active = 'désactiver';
    }else{
      activeVal = 1;
      active = 'activer';
    }
     
    this.$root.$confirm
    .open(
        "Activation index",
        "Êtes-vous sûr(e) de vouloir "+active+" cet index ?",
        {}
    )
    .then(confirm => {
        if (confirm) {
          this.$api.post(`stock-exchange/active-stock?stock=${index.stockExchange.id}&active=${activeVal}`).
                then(response => {
                  this.$refs.grid.getModels();
                }).catch((e) => {
                    this.error = e.response.data;
                }).finally(() => {

                });
        }
    });
  }
}
</script>
